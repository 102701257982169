const envDomain = "staging.cyberskiller.com";

export const environment = {
  displayedDomain: `alk.${envDomain}`,
  production: true,
  apiLocation: "/api/",
  apiDomain: `https://api.${envDomain}`,
  wsLocation: `wss://api.${envDomain}/ws/`,
  judge0ApiLocation: `https://judge.${envDomain}/`,
  captchaMocked: false,
  defaultLang: "pl",
};
